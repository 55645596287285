import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts/highstock.js';
import HighchartsReact from 'highcharts-react-official';
import {
  performanceStackedChartOptions,
  yScaleFormaterNoBenchmarks
} from '../../charts/chartOptions';
import { MetricChartType } from '../../charts/types';
import _ from 'lodash';
import { CardContentContext } from './base/BaseCard';
import { AlertS } from '@weave-mui/icons-weave';
import {Benchmark, DataPointValue, MetricEvaluationResult} from '../../types/metrics';
import * as conv from '../../conversions';
import i18n from '../../i18n';
import Box from '@weave-mui/box';
import { simpleCardDataStyles } from './base/Card.stylesheet';
import { EllipsisTypography } from '../../shared/EllipsisTypography';
import { InfoTooltipIcon } from '../../shared/InfoTooltipIcon';
import { tooltipPlacement } from '@weave-mui/enums';
import {MetricPopupLegend, MetricPopupLegendProps} from "./shared/MetricPopupLegend";
import {
  benchmarkSeriesStructure,
  chartXValue,
  putBenchmarksOnChart,
  scaleYAxis
} from "../../charts/benchmarkChartUtils";
import { MetricCardData } from '../../types/layout';

export const MetricCard: React.FC = () => {
  const { data: cardData, settings: cardSettings,cardId } = useContext(CardContentContext) || {}
  const [ popupLegendProps, setPopupLegendProps ] =
    useState<MetricPopupLegendProps>({
      isVisible: false, selectedDataSeries: '', chartData: undefined, anchor: undefined
    });

  const { value,
    unitSymbol,
    name,
    breakdown,
    isPartialResult,
    warningMessage,
    benchmarks} = (cardData as DataPointValue);

  const {
    selectedBenchmarkIds
  } = cardSettings as MetricCardData;

  const metricResult = (value as MetricEvaluationResult)?.result;
  const isNegative = metricResult && metricResult < 0;
  const metricValue =
    (metricResult && conv.round(!isNegative ? metricResult : Math.abs(metricResult), 2)) || 0;
  const metricLabel =
    isPartialResult && value === i18n.t('cardConfig.notApplicableText')
      ? value
      : ((metricResult && conv.round(metricResult, 2)) || 0).toLocaleString();
  const metricHasBreakdown = !!(breakdown && breakdown?.length);
  const subTitle = unitSymbol;
  const chartRef = useRef(null);
  const benchmarkSeries = [];
  if (benchmarks && benchmarks?.length > 0) {
    benchmarks.filter( benchmark =>
      selectedBenchmarkIds?.findIndex( selectedId => selectedId === benchmark.id ) !== -1)
        .forEach((benchmark: Benchmark) => {
          benchmarkSeries.push({...benchmarkSeriesStructure,
            id: benchmark.id,
            name: benchmark.displayName,
            data: [[chartXValue, benchmark.value]]});
        });
  }
  const getSectionTitle = (chart: MetricChartType, type: string) => {
    if (type === benchmarkSeriesStructure.type) { //benchmark type
      return i18n.t('analysis.dataPoints.dropdownValues.benchmarks');
    }
    return `${chart.name} - ${chart.unit}`;
  }

  useEffect(() => {
    let timeoutId;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (chartRef.current) {
          chartRef.current.chart.reflow();
        }
      }, 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (!chart) {
      return;
    }
    scaleYAxis(chart);
  }, [metricValue, benchmarks, chartRef, selectedBenchmarkIds]);

  useEffect(() => {
    const legendChartData = {
      ...chart,
      data: chart.data
        .reduce((acc, currentValue) => {
          const section = getSectionTitle(chart, currentValue.type) ?? chart.name;
          const series = chartRef?.current?.chart.series.find((serie) => serie.name === currentValue.name);
          const color = currentValue.type === 'scatter' ? null : series?.color;
          const chartItem = {...currentValue, color};
          if (!acc.hasOwnProperty(section)) {
            acc[section] = [chartItem]; //no color for benchmarks
          } else {
            acc[section].push(chartItem);
          }
          return acc;
        }, {})
    };
    setPopupLegendProps({
      ...popupLegendProps,
      chartData: legendChartData,
      anchor: chartRef?.current?.chart.seriesGroup.element
    });
  }, [chartRef, benchmarks]);

  let chart: MetricChartType = {
    name,
    mainValue: metricValue,
    unit: unitSymbol,
    showChartLegend: metricHasBreakdown,
    data: metricHasBreakdown
      ? [ ...breakdown
        .filter((item) => !!item.value)
        .map((item) => ({ name: item.name, pointStart: 1, data: [item.value] })),
        ...benchmarkSeries]
      : [
        {
          name,
          pointStart: 1,
          data: [metricValue]
        },
        ...benchmarkSeries
      ],
    displayWarning: isPartialResult,
    warningMessage: warningMessage,
  };

  const options = _.cloneDeep(performanceStackedChartOptions);
  options.plotOptions.column.pointPlacement = (metricHasBreakdown && -0.3) || -0.1;
  options.series = chart.data;
  options.xAxis.categories = [chart.name];

  options.chart.events = {
    render: function() {
      const chart = this;
      putBenchmarksOnChart(chart);
    }
  }
  options.legend = {
    ...options.legend,
    enabled: false
  };
  options.tooltip = {
    ...options.tooltip,
    enabled: false
  };
  options.plotOptions.series.point.events = {
    mouseOver: function(_){
      const isBenchmark = this.series.type === benchmarkSeriesStructure.type;
      if (popupLegendProps.selectedDataSeries !== this.series.name) {
        setPopupLegendProps(prevState => {
          return {
            ...prevState,
            selectedDataSeries: this.series.name,
            isVisible: true
          };
        });
      }
      return false;
    },

    mouseOut: function( _ ){
      setPopupLegendProps(prevState => {
        return {
          ...prevState,
          isVisible: false,
        };
      });
    }
  };

  options.yAxis.labels = {
    ...options.yAxis.labels,
    formatter: yScaleFormaterNoBenchmarks(value, isNegative)
  }


  const renderBarChartMetricData = () => {
    return (
      <Box sx={{ display: 'flex', paddingTop: '8px', paddingRight: '4px', paddingBottom: '36px', paddingLeft: '0px' }}>
        {(chart.displayWarning && (
          <InfoTooltipIcon
            icon={<AlertS sx={{ paddingRight: '5px', paddingBottom: '4px', cursor: 'pointer' }} color="warning" />}
            tooltipContent={chart?.warningMessage}
            tooltiPlacement={tooltipPlacement.BOTTOM_START}
            maxWidth={'240px'}
          />
        )) || <></>}
        <EllipsisTypography mainValue={metricLabel} secondaryValue={subTitle} enableTooltip={true} />
      </Box>
    );
  };

  const mouseOutCb = useCallback(() => {
    setPopupLegendProps(prevState => {
      return {...prevState, isVisible: false};
    });
  }, []);

  const renderHighCharts = () => {
    return (
      <div className="performance-chart" style={{ overflow: 'visible' }} onMouseOut={mouseOutCb}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          immutable={!cardId} //this fixes the issue when switching between metric in configure card. It ensures that the chart is re initialized when the options are changed
          ref={chartRef} />
        {
          chartRef?.current && popupLegendProps.chartData && cardId &&
            <MetricPopupLegend props={popupLegendProps}/>
        }
      </div>
    );
  };

  return (
    <Box sx={{ ...simpleCardDataStyles }}>
      {renderBarChartMetricData()}
      {renderHighCharts()}
    </Box>
  );
};
